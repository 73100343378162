import { State } from "vuex-class";
import { Vue, Component } from "vue-property-decorator";
import { Location } from "vue-router";
import { HtmlElementId, HtmlElementClassName } from "@/types/element";
import asyncQuerySelector from "@/helpers/asyncQuerySelector";
import {
  ProductTourIntro,
  ProductTourStep,
} from "@/plugins/product-tour/types";
import { EventName, EventTarget, StepAlias } from "@/types/productTour";
import { sleep } from "@helpers";
import { PaymentMethodId } from "@/types/billing";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";
import { Role } from "@/types/role";
import { RootState } from "@store/types";

@Component
export default class MainProductTour extends Vue {
  @State("isReady") private readonly isReadyApp!: RootState["isReady"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @profileModule.Getter("profileId")
  private readonly profileIdGetter!: ProfileGetters["profileId"];

  private productTourIntro!: ProductTourIntro | undefined;

  private onceShowed = false;
  private confirmCloseDialog = {
    showed: false,
    confirmed: false,
  };

  private onConfirmCloseDialog() {
    this.confirmCloseDialog.showed = false;
    this.productTourIntro?.destroy();
  }

  private async mounted() {
    let refreshProductTourIntroTimeoutId = 0;

    this.$router.onReady(() => {
      const unwatchShowMainIntro = this.$watch(
        () => {
          const routeName = this.$route.name || "";
          const userIsMediabuyer = this.userHasRoleGetter(Role.ROLE_MEDIABUYER);
          const userIsTeamlead = this.userHasRoleGetter(Role.ROLE_TEAMLEAD);
          const profileId = this.profileIdGetter;
          const isShowMainIntro =
            this.isReadyApp &&
            ["cards-with-balance", "cards-with-limit"].includes(routeName) &&
            !this.$route.query.showedIssueCard &&
            (userIsMediabuyer || userIsTeamlead) &&
            !!profileId;

          return {
            isShowMainIntro,
            userIsMediabuyer,
            userIsTeamlead,
            profileId,
          };
        },
        async ({ isShowMainIntro, userIsTeamlead, profileId }) => {
          if (!isShowMainIntro) {
            return;
          }

          const enabledMainIntro =
            this.$route.query.main_product_tour === "1" ||
            (!localStorage.getItem(`productTourIntro_${profileId}`) &&
              !!(await asyncQuerySelector(`#${HtmlElementId.welcomeScreen}`)));

          if (!enabledMainIntro) return;

          const goToRoute = (to: Location) => {
            const oldFullPath = this.$router.currentRoute.fullPath;
            const newFullPath = this.$router.resolve(to).resolved.fullPath;

            if (oldFullPath !== newFullPath) {
              return this.$router.push(newFullPath);
            }
          };

          const moveToStep = async ({
            action,
            group,
            stepIndex,
          }: {
            action?: "next" | "prev";
            stepIndex?: number;
            group?: string;
          }) => {
            if (!this.productTourIntro) return;

            this.productTourIntro.toggleLoading(true);

            window.clearTimeout(refreshProductTourIntroTimeoutId);

            if (action === "next") {
              stepIndex = (this.productTourIntro.getActiveIndex() ?? -1) + 1;
            } else if (action === "prev") {
              stepIndex = (this.productTourIntro.getActiveIndex() ?? 1) - 1;
            } else if (group) {
              stepIndex = steps.findIndex((step) => step.group === group);
            }

            console.log("stepIndex", stepIndex, group);

            const step =
              typeof stepIndex === "number" ? steps[stepIndex] : undefined;

            if (step?.goTo) {
              goToRoute(step.goTo);
            }

            const elementSelector =
              typeof step?.element === "string" ? step.element : null;

            this.productTourIntro.toggleIntroModel(true, stepIndex);

            if (elementSelector) {
              const element = await asyncQuerySelector(elementSelector, {
                onlyVisibled: true,
              });

              element?.scrollIntoView({
                block: "center",
              });

              await sleep(100);
            }

            if (!this.productTourIntro.isActive()) {
              this.productTourIntro.drive(stepIndex);
            } else if (step && typeof stepIndex === "number") {
              this.productTourIntro.moveTo(stepIndex);
            } else {
              this.productTourIntro.destroy();
            }

            this.productTourIntro.toggleLoading(false);
          };

          const refreshProductTourIntro = (timeout = 400) => {
            window.clearTimeout(refreshProductTourIntroTimeoutId);
            refreshProductTourIntroTimeoutId = window.setTimeout(() => {
              this.productTourIntro?.refresh();
            }, timeout);
          };

          this.onceShowed = this.$route.query.main_product_tour !== "1";

          let steps: ProductTourStep[] = [
            {
              group: "welcome",
              element: `#${HtmlElementId.welcomeScreen}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                showButtons: ["next", "close"],
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step1"
                ),
                nextBtnText: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.buttons.lets_start"
                ),
              },
            },
            {
              group: "welcome",
              element: `#${HtmlElementId.headerWallets}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step2"
                ),
                showButtons: ["next", "close", "previous"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "welcome",
              element: `#${HtmlElementId.headerStatDecline}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step3"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.balanceCardsSidebarItem}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step4"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.cardsFiltersContainerId}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step5"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.cardsDownloadFilesContainerId}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step6"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.issueCardButton}`,
              alias: StepAlias.WELCOME_SCREEN,
              goTo: {
                name: "cards-with-balance",
                query: {},
              },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step7"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.issueCard}`,
              alias: StepAlias.WELCOME_SCREEN,
              goTo: {
                name: "cards-with-balance",
                query: { showedIssueCard: "1" },
              },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step8"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.issueCardBanksList} .${HtmlElementClassName.selectBankIssueCard}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step9"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
                onNextClick: () => {
                  this.$productTour.dispatchEvent(
                    EventName.ISSUE_CARD_SELECTED_BANK,
                    0,
                    EventTarget.PRODUCT_TOUR
                  );

                  moveToStep({ action: "next" });
                },
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.issueCardBaseForm}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step10"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
                onPrevClick: () => {
                  this.$productTour.dispatchEvent(
                    EventName.ISSUE_CARD_SELECTED_BANK,
                    null,
                    EventTarget.PRODUCT_TOUR
                  );

                  moveToStep({ action: "prev" });
                },
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.issueCardTotalInfo}`,
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step11"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cards",
              element: `#${HtmlElementId.issueCardBankFeeInfo}`,
              goTo: {
                name: "cards-with-balance",
                query: { showedIssueCard: "1" },
              },
              alias: StepAlias.WELCOME_SCREEN,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step12"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_balance_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "balance-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "balance-cards",
              element: `#${HtmlElementId.balanceCardsTable} tbody tr`,
              goTo: {
                name: "cards-with-balance",
                query: {},
              },
              alias: StepAlias.MOCK_CARDS_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step13"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
                onPrevClick: async () => {
                  moveToStep({ action: "prev" });

                  await sleep(200);

                  this.$productTour.dispatchEvent(
                    EventName.ISSUE_CARD_SELECTED_BANK,
                    0,
                    EventTarget.PRODUCT_TOUR
                  );
                },
              },
            },
            {
              group: "balance-cards",
              element: `.${HtmlElementClassName.changeCardBalanceButton}`,
              alias: StepAlias.MOCK_CARDS_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step14"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "balance-cards",
              element: `.${HtmlElementClassName.cardsAutoRefillButton}`,
              alias: StepAlias.MOCK_CARDS_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step15"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "balance-cards",
              element: `.${HtmlElementClassName.cardActions}`,
              alias: StepAlias.MOCK_CARDS_ACTIONS,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step16"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "balance-cards",
              element: `.${HtmlElementClassName.cardTransactionsActionButton}`,
              alias: StepAlias.MOCK_CARDS_ACTIONS,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step17"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "balance-cards",
              element: `.${HtmlElementClassName.cardStatisticActionButton}`,
              alias: StepAlias.MOCK_CARDS_ACTIONS,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step18"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "balance-cards",
              element: `.${HtmlElementClassName.cardPauseActionButton}`,
              alias: StepAlias.MOCK_CARDS_ACTIONS,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step19"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "balance-cards",
              element: `.${HtmlElementClassName.cardCloseActionButton}`,
              goTo: {
                name: "cards-with-balance",
              },
              alias: StepAlias.MOCK_CARDS_ACTIONS,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step20"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_limit_cards"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "limit-cards",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "limit-cards",
              element: `#${HtmlElementId.limitCardsTable} tbody tr`,
              alias: StepAlias.MOCK_CARDS_LIST,
              goTo: { name: "cards-with-limit" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step21"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_refill"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "refill",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "limit-cards",
              element: `.${HtmlElementClassName.cardSetLimitButton}`,
              alias: StepAlias.MOCK_CARDS_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step22"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_refill"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "refill",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "limit-cards",
              element: `.${HtmlElementClassName.cardSetLimitForm}`,
              goTo: { name: "cards-with-limit" },
              alias: StepAlias.MOCK_CARDS_SET_LIMIT,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step23"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_refill"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "refill",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "refill",
              element: `#${HtmlElementId.refillSidebarItem}`,
              goTo: { name: "refill" },
              enabled: userIsTeamlead,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step24"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "refill",
              element: `#${HtmlElementId.paymentMethods}`,
              enabled: userIsTeamlead,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step25"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "refill",
              element: `#${PaymentMethodId.USDT_TRANSFER}`,
              enabled: userIsTeamlead,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step26"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "refill",
              element: `#${HtmlElementId.usdtTransferConverter}`,
              enabled: userIsTeamlead,
              alias: StepAlias.PAYMENT_METHOD_USDT_TRANSFER,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step27"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "refill",
              element: `#${HtmlElementId.usdtTransferWallet}`,
              enabled: userIsTeamlead,
              goTo: { name: "refill" },
              alias: StepAlias.PAYMENT_METHOD_USDT_TRANSFER,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step28"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_currency_converter"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "currency-converter",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "currency-converter",
              element: `#${HtmlElementId.converterSidebarItem}`,
              goTo: { name: "currency-converter" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step29"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_transactions"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "transactions",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "currency-converter",
              element: `#${HtmlElementId.currencyConverterForm}`,
              goTo: { name: "currency-converter" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step30"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_transactions"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "transactions",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "transactions",
              element: `#${HtmlElementId.transactionsSidebarItem}`,
              goTo: { name: "transactions" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step31"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_cash_flow"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "cash-flow",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "transactions",
              element: `#${HtmlElementId.transactionsFiltersContainerId}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step32"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_cash_flow"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "cash-flow",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "transactions",
              element: `#${HtmlElementId.transactionsFiltersButtonId}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step33"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_cash_flow"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "cash-flow",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "transactions",
              element: `#${HtmlElementId.transactionsDownloadFilesContainerId}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step34"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_cash_flow"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "cash-flow",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "transactions",
              element: `#${HtmlElementId.transactionsTable} tbody tr`,
              goTo: { name: "transactions" },
              alias: StepAlias.MOCK_TRANSACTIONS_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step35"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_cash_flow"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "cash-flow",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cash-flow",
              element: `#${HtmlElementId.cashFlowSidebarItem}`,
              goTo: { name: "cash-flow" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step36"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_team"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "team",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cash-flow",
              element: `#${HtmlElementId.cashFlowFiltersContainerId}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step37"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_team"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "team",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cash-flow",
              element: `#${HtmlElementId.cashFlowFiltersButtonId}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step38"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_team"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "team",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cash-flow",
              element: `#${HtmlElementId.cashFlowDownloadFilesContainerId}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step39"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_team"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "team",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "cash-flow",
              element: `#${HtmlElementId.cashFlowTable} tbody tr`,
              goTo: { name: "cash-flow" },
              alias: StepAlias.MOCK_CASH_FLOW_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step40"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_team"
                    ),
                    enabled: userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "team",
                      });
                    },
                  },
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    enabled: !userIsTeamlead,
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "team",
              element: `#${HtmlElementId.teamSidebarItem}`,
              goTo: { name: "team" },
              enabled: userIsTeamlead,
              alias: StepAlias.MOCK_TEAM_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step41"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "team",
              element: `#${HtmlElementId.teamInviteUrlField}`,
              enabled: userIsTeamlead,
              alias: StepAlias.MOCK_TEAM_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step42"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "team",
              element: `.${HtmlElementClassName.teamCardIssueLimit}`,
              enabled: userIsTeamlead,
              alias: StepAlias.MOCK_TEAM_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step43"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "team",
              element: `.${HtmlElementClassName.teamWalletTransferFunds}`,
              enabled: userIsTeamlead,
              alias: StepAlias.MOCK_TEAM_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step44"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "team",
              element: `.${HtmlElementClassName.teamAutoRefillButton}`,
              enabled: userIsTeamlead,
              alias: StepAlias.MOCK_TEAM_LIST,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step45"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "team",
              element: `.${HtmlElementClassName.teamMemberDeleteAction}`,
              goTo: { name: "team" },
              enabled: userIsTeamlead,
              alias: StepAlias.MOCK_TEAM_ACTIONS,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step46"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_statistic"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "statistic",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "statistic",
              element: `#${HtmlElementId.statisticSidebarItem}`,
              goTo: { name: "statistic" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step47"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_profile"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "profile",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "statistic",
              element: `#${HtmlElementId.statisticFiltersContainerId}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step48"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_profile"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "profile",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "statistic",
              element: `#${HtmlElementId.statisticGroupByField}`,
              enabled: userIsTeamlead,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step49"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_profile"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "profile",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "statistic",
              element: `#${HtmlElementId.statisticDownloadFilesContainerId}`,
              goTo: { name: "statistic" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step50"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_profile"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "profile",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "profile",
              element: `#${HtmlElementId.profileSidebarItem}`,
              goTo: { name: "profile" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step51"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_additional_info"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "additional-information",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "profile",
              element: `#${HtmlElementId.profileBindPhoneNumberField}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step52"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_additional_info"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "additional-information",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "profile",
              element: `#${HtmlElementId.profileSecondFactorField}`,
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step53"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_additional_info"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "additional-information",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "profile",
              element: `#${HtmlElementId.profileMaxSessionLimitField}`,
              goTo: { name: "profile" },
              popover: {
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step54"
                ),
                showButtons: ["next", "previous", "close"],
                customButtons: [
                  {
                    text: this.$vuetify.lang.t(
                      "$vuetify.dashboard.main_product_tour.buttons.move_to_additional_info"
                    ),
                    onClick: () => {
                      moveToStep({
                        group: "additional-information",
                      });
                    },
                  },
                ],
              },
            },
            {
              group: "additional-information",
              element: `#${HtmlElementId.supportSidebarItem}`,
              popover: {
                showButtons: ["next", "previous", "close"],
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step55"
                ),
              },
            },
            {
              group: "additional-information",
              element: `#${HtmlElementId.faqSidebarItem}`,
              popover: {
                showButtons: ["next", "previous", "close"],
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step56"
                ),
              },
            },
            {
              group: "additional-information",
              element: `#${HtmlElementId.headerBellButton}`,
              popover: {
                showButtons: ["next", "previous", "close"],
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step57"
                ),
              },
            },
            {
              popover: {
                nextBtnText: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.buttons.finish_intro"
                ),
                showButtons: ["next", "close"],
                description: this.$vuetify.lang.t(
                  "$vuetify.dashboard.main_product_tour.step58"
                ),
              },
            },
          ];

          steps = steps.filter(({ enabled = true }) => enabled);

          this.productTourIntro = await this.$productTour.intro({
            showProgress: true,
            allowClose: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            stagePadding: 4,
            introType: "product-tour",
            steps: steps,
            onCloseClick: () => {
              if (this.productTourIntro?.hasNextStep()) {
                this.confirmCloseDialog.showed = true;
              } else {
                this.productTourIntro?.destroy();
              }
            },
            onDestroyed: () => {
              localStorage.setItem(`productTourIntro_${profileId}`, "1");
              goToRoute({ name: "cards-with-balance" });
            },
            onPrevClick: () => {
              moveToStep({ action: "prev" });
            },
            onNextClick: () => {
              moveToStep({ action: "next" });
            },
            onDestroyStarted: () => {
              window.clearTimeout(refreshProductTourIntroTimeoutId);
            },
            onHighlightStarted: () => {
              window.clearTimeout(refreshProductTourIntroTimeoutId);
            },
            onDeselected: () => {
              window.clearTimeout(refreshProductTourIntroTimeoutId);
            },
            onHighlighted: () => {
              refreshProductTourIntro();
            },
          });

          moveToStep({ stepIndex: 0 });
        },
        {
          immediate: true,
        }
      );

      this.$watch(
        () => {
          return this.onceShowed;
        },
        (onceShowed) => {
          if (!onceShowed) return;

          unwatchShowMainIntro();
        },
        { immediate: true }
      );

      this.$watch(
        () => {
          return this.confirmCloseDialog.showed;
        },
        (showed) => {
          document.documentElement.classList.toggle(
            "driver-product-tour-close-confirm-dialog",
            showed
          );
        },
        {
          immediate: true,
        }
      );
    });

    this.$once("hook:beforeDestroy", () => {
      this.productTourIntro?.destroy();
      document.documentElement.classList.remove(
        "driver-product-tour-close-confirm-dialog"
      );
      window.clearTimeout(refreshProductTourIntroTimeoutId);
    });
  }
}
